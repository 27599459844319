/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-16 21:22:47
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-16 21:25:37
 */
import Activity from './Activity.vue'
export default Activity
