/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-17 09:14:08
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-17 09:15:45
 */
import ActivityTable from './ActivityTable.vue'
export default ActivityTable
